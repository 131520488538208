export const educationData = [
    {
        id: 3,
        institution: 'University of Agriculture Faislabad ',
        course: 'BS Computer Science',
        startYear: '2019',
        endYear: '2023'
    },
    {
        id: 2,
        institution: 'G.C College Burewala',
        course: 'Intermediate',
        startYear: '2017',
        endYear: '2019'
    },
    {
        id: 1,
        institution: 'Tabindah Model Hgh School',
        course: ' Matriculation',
        startYear: '2005',
        endYear: '2017'
    }
]