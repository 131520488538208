/* eslint-disable */
import { AiFillTikTok, AiOutlineMail } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { CgMediaPodcast } from "react-icons/cg";
import { FaRegNewspaper, FaTabletAlt } from "react-icons/fa";
import { SiGoogleads, SiMeta } from "react-icons/si";

 

export const servicesData = [
    // {
    //     id: 1,
    //     title: 'Marketing',
    //     icon: <BiShoppingBag/>
    // },
    {
        id: 2,
        title: 'Content Writing',
        icon: <BiPencil /> 
    },
    {
        id: 3,
        title: 'Email Marketing',
        icon: <AiOutlineMail />
    },
    {
        id: 4,
        title: 'Google Ads',
        icon: <SiGoogleads />
    },
    // {
    //     id: 5,
    //     title: 'Tutoring',
    //     icon: <FaChalkboardTeacher />
    // },

    {
        id: 6,
        title: 'Social Media Marketing',
        icon: <CgMediaPodcast />
    },
    // {
    //     id: 8,
    //     title: 'Pinterest Virtual Assistant',
    //     icon: <FaPinterest />
    // }, 
    
    // {
    //     id: 10,
    //     title: 'Data Entry', 
    //     icon: <BsClipboardData />
    // },
    {
        id: 11,
        title: 'Ebook Writing',
        icon: <FaTabletAlt />
    },
    // {
    //     id: 12,
    //     title: 'Audio Transcription',
    //     icon: <AiFillAudio />
    // },
    {
        id: 13,
        title: 'Blog Posting',
        icon: <FaRegNewspaper />
    },
    
    {
        id: 13,
        title: 'Facebook Marketing',
        icon: <SiMeta />
    },
    {
        id: 13,
        title: 'Tiktok Marketing',
        icon: <AiFillTikTok />
    },
]

// Uncomment your required service.
// Couldn't find the required services? Raise an issue on github at https://github.com/hhhrrrttt222111/developer-portfolio/issues/new
// You can also add on your own 😉.