/* eslint-disable */
import boy1 from '../assets/svg/testimonials/boy1.svg';
import boy2 from '../assets/svg/testimonials/boy2.svg';
import girl1 from '../assets/svg/testimonials/girl1.svg';
import girl2 from '../assets/svg/testimonials/girl2.svg';



export const testimonialsData = [
    {
        id: 1,
        name: 'blossombaby808',
        title: 'United States',
        text: "Muassab provided good work in marketing strategy, demonstrating cooperation and politeness.",
        image: boy2
    },
    {
        id: 2,
        name: 'velvetsunset',
        title: 'United states',
        text: "I recently hired him for marketing services and he maintained a professional demeanor, consistently keeping me updated with the progress.",
        image: girl2
    },
    {
        id: 3,
        name: 'hessel-ortiz',
        title: 'Mexico',
        text: "Really satisfied with the analysis. Very professional on the job done. :)",
        image: girl1
    },
    {
        id: 4,
        name: 'mzdowrght',
        title: 'United States',
        text: "Quality! Attention to detail! I look forward to continuing to do work in the future!",
        image: boy1
    },
    // {
    //     id: 5,
    //     name: 'Rachel Green',
    //     title: 'Sales Executive',
    //     text: 'Ralph Lauren was one of the best things that ever happened to me. My portfolio made that dream happen. I owe you guys.',
    //     image: girl3
    // },
    // {
    //     id: 6,
    //     name: 'Ross Geller',
    //     title: 'Paleontologist',
    //     text: "Be it 'Rocks' or 'Bones', you need a website to display it. This is it. Great work guys !",
    //     image: boy3
    // }
]

