import resume from '../assets/pdf/massab_cv.pdf';
import profileimage from '../assets/png/massab.png';

export const headerData = {
    name: 'Muaasab Sabir',
    title: "Digital Marketing & Ads Manager",
    desciption:"Experienced Digital Marketing & Ads Manager specializing in creating data-driven strategies that drive traffic, increase brand visibility, and maximize ROI. Expert in Google Ads, social media campaigns, and performance analytics to deliver measurable business growth. Let’s take your brand to the next level!",
    image: profileimage,
    resumePdf: resume
}
