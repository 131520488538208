import React from 'react';
import { Helmet } from 'react-helmet';

import { About, Achievement, Contacts, Education, Footer, Landing, Navbar, Services, Testimonials } from '../../components';
import { headerData } from '../../data/headerData';

function Main() {
    return (
        <div>
            <Helmet>
                <title>{headerData.name} - Porfolio</title>
            </Helmet>

            <Navbar />        
            <Landing />
            <About />
            <Education />
            {/* <Skills /> */}
            {/* <Experience /> */}
            {/* <Projects /> */}
            <Achievement />
            {/* <SuccessStories /> */}
            <Services />
            <Testimonials />
            {/* <Blog /> */}
            <Contacts />
            <Footer />
        </div>
    )
}

export default Main
