export const achievementData = {
    bio : "With a passion for excellence and continuous learning, I have successfully earned certifications that highlight my expertise in the digital marketing and e-commerce landscape. From mastering Google Ads campaigns to excelling in Amazon Seller Account Management, each achievement reflects my dedication to staying ahead in a competitive industry and delivering impactful results",
    achievements : [
        {
            id: 4,
            title: "Google Ads Certification",
            details: "Comprehensive training on running effective Google Ads campaigns, including keyword research, bidding strategies, and performance analysis.",
            date: "Nov, 2021",
            field: "Search Engine",
            image: "https://images.unsplash.com/photo-1573141597928-403fcee0e056?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=667&q=80"
        },        
        {
            id : 3,
            title : 'Digital Marketing Certification',
            details: "Certification in advanced digital marketing techniques including SEO, SEM, and social media marketing.",
            date : 'Sept, 2020',
            field : 'Digital Marketing',
            image : 'https://images.unsplash.com/photo-1489389944381-3471b5b30f04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        },
        {
            id : 2,
            title: "Amazon Virtual Assistant Certification",
            details: "Comprehensive training on managing Amazon Seller accounts, including product listing, inventory management, and customer support.",
            date : 'May, 2018',
            field: "Amazon Seller Account",
            image : 'https://images.unsplash.com/photo-1523474253046-8cd2748b5fd2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        },

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/